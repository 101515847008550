import { useLayoutEffect } from '@loveholidays/design-system';
import { useState } from 'react';

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? 'client' : 'server';

  useLayoutEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};
